// import Highway from '@dogstudio/highway';
// import BaseTrans from '@js/modules/Load/base.Transition';
// import Attr from '@utils/attributes';
// import { GA_CODE } from '@utils/environment';

export class Load {
  constructor(app) {
    this.app = app;

    /*
    this.links = [];
    this.links_lang = [];


    // Call Highway.Core once.
    // Store it in a variable to use events
    const H = new Highway.Core({
      transitions: {
        default: BaseTrans
        // contextual: {
        //   projet: ProjetTrans,
        // }
      }
    });

    // On load:
    // Query admin bar links and detach them from Highway transitions
    const adminBarLinks = document.querySelectorAll('#wpadminbar a');
    H.detach(adminBarLinks);

    // Listen the `NAVIGATE_OUT` event
    // This event is sent everytime the `out()` method of a transition is run to hide a `data-router-view`
    // H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    //   debug('========================');
    //   debug('NAVIGATE_OUT', from);
    //   // this.app.onPageOut();
    // });

    // Listen the `NAVIGATE_IN` event
    // This event is sent everytime a `data-router-view` is added to the DOM Tree
    H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
      debug('NAVIGATE_IN', to, location);

      // Body
      document.body.className = to.page.body.className;

      // Init new page
      document.title = to.page.title;

      // Nav
      this.links.forEach(l => {
        Attr.removeClass(l.parentNode, 'current-menu-item');
        Attr.removeClass(l.parentNode, 'current_page_item');

        // if (l.href === location.href && trigger === 'popstate') {
        if (l.href === location.href) {
          Attr.addClass(l.parentNode, 'current-menu-item current_page_item');
        }
      });
      this.links_lang.forEach(l => {
        Attr.removeClass(l.parentNode, 'current-lang');

        // if (l.href === location.href && trigger === 'popstate') {
        if (l.href === location.href) {
          Attr.addClass(l.parentNode, 'current-lang');
        }
      });
      // if (typeof trigger === 'object') Attr.addClass(trigger.parentNode, 'current-menu-item current_page_item');
    });

    // Listen the `NAVIGATE_END` event
    // This event is sent everytime the `done()` method is called in the `in()` method of a transition
    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {
      debug('NAVIGATE_END', to, from);
      this.app.DOM.quicklink = to.view;

      // load scripts / styles from new pages
      this.manageScripts(to);
      this.manageStyles(to);

      // Check Anchor
      if (location.anchor) {
        // Get element
        const el = document.querySelector(location.anchor);

        if (el) {
          // Scroll to element
          window.scrollTo(el.offsetLeft, el.offsetTop);
        }
      }

      // Fire Google Analytics pageview
      // if (typeof gtag === 'function') {
      //   // send statics by Google Analytics(gtag.js)
      //   gtag('config', App.ga_code, { 'page_path': data.next.url, 'use_amp_client_id': true })
      // } else {
      //   // send statics by Google Analytics(analytics.js) or Google Tag Manager
      //   if (typeof ga === 'function') {
      //     var trackers = ga.getAll()
      //     trackers.forEach(function (tracker) {
      //       ga(tracker.get('name') + '.send', 'pageview', data.next.url, { 'useAmpClientId': true })
      //     })
      //   }
      // }

      // Analytics (from Highway doc)
      if (typeof gtag !== 'undefined') {
        // send statics by Google Analytics(gtag.js)
        // eslint-disable-next-line
        // gtag('config', GA_CODE, {
        gtag('config', THEME_GA_CODE, {
          'page_path': location.pathname,
          'page_title': to.page.title,
          'page_location': location.href,
          'use_amp_client_id': true
        });
      }
      else if (typeof ga === 'function') {
        // send statics by Google Analytics(analytics.js) or Google Tag Manager
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
          // eslint-disable-next-line
          ga(tracker.get('name') + '.send', 'pageview', location.pathname, { 'useAmpClientId': true });
        });
      }

      // Query admin bar links, and new page's admin bar links
      const adminBarLinks = document.querySelectorAll('#wpadminbar a');
      const newAdminBarLinks = to.page.body.querySelectorAll('#wpadminbar a');

      // Replace every admin bar link's href value with new value
      for (let i = 0; i < newAdminBarLinks.length; i++) {
        adminBarLinks[i].href = newAdminBarLinks[i].href;
      }

      // Detach admin bar links from Highway transitions
      H.detach(adminBarLinks);

      // Init the page
      this.app.pageInit();

      debug('========================');
    });
    */
  }

  init() {
    debug('call init from Load');

    // this.links = [...document.querySelectorAll('#header-menu-list a'), ...document.querySelectorAll('#primary-menu-list a')];
    // this.links_lang = [...document.querySelectorAll('.languages_switcher a')];
    // // debug(this.links);

    let current_url = window.location.href;
    if (current_url.indexOf('#') !== -1) {
      current_url = current_url.substring( 0, current_url.indexOf('#') );
    }
    debug('current url is ' + current_url);

    [...document.querySelectorAll('a')].forEach((a) => {

        if (
            a.classList.contains('no-ajax') ||
            a.classList.contains('booking-link') ||
            a.classList.contains('header-gift-link') ||
            a.classList.contains('parvbox')
        ) {
        } else {
            const url = a.getAttribute('href');
            let url_withtout_hash = url;
            let url_has_hash = url.indexOf('#') !== -1;
            if (url_has_hash) {
            url_withtout_hash = url.substring( 0, url.indexOf('#') );
            }

            if (url && url.indexOf(site_url) === 0 && url.indexOf('.zip') === -1 && url.indexOf('/wp-admin') === -1)
            {
                // debug('link url is', url, url_withtout_hash);
                // debug(url_withtout_hash === current_url ? 'same url' : 'different url');

                if (!url_has_hash || (url_has_hash && url_withtout_hash !== current_url)) {
                    a.addEventListener('click', (e) => {
                    // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
                    if (e.metaKey || e.ctrlKey || e.target.getAttribute('target')) {
                        // return w.open(element.src, '_blank');
                        return;
                    }

                    e.preventDefault();

                    debug('page OUT', url);

                    // Page animation
                    app.onPageOut().then(() => {
                        // setTimeout(() => {
                        //   window.location = url;
                        // }, 300);

                        app.onPageChange().then(() => {
                        window.location = url;
                        done();
                        });
                    });

                    // html.classList.remove('is-ended');
                    // html.classList.add('is-leaving');
                    // html.classList.remove('is-ready');
                    // rafTimeout(() => {
                    //   window.location = url;
                    // }, 300);
                    });
                }
            }
        }
    });
  }

  // manageScripts(to) {
  //   // Your main JS file, used to prepend other scripts
  //   const main = document.querySelector('#bao-script-js');

  //   const new_scripts = [...to.page.querySelectorAll('script:not([data-no-reload])')];
  //   const old_scripts = [...document.querySelectorAll('script:not([data-no-reload])')];
  //   const old_scripts_to_remove = [];

  //   // debug('new scripts', new_scripts);
  //   // debug('old scripts', old_scripts);

  //   // Compare Scripts
  //   for (let i = 0; i < old_scripts.length; i++) {
  //     let founded = false;
  //     const tmp_old = old_scripts[i];

  //     // debug('script id:', tmp_old.id);

  //     for (let j = 0; j < new_scripts.length; j++) {
  //       const tmp_new = new_scripts[j];

  //       if (
  //         tmp_old.id === tmp_new.id ||
  //         tmp_old.outerHTML === tmp_new.outerHTML
  //       ) {
  //         // if (tmp_old.id)
  //         //   debug('-> same script');
  //         // else
  //         //   debug('-> same script', tmp_old.outerHTML);

  //         // Create Shadow Script
  //         const script = document.createElement(tmp_old.tagName);

  //         // Loop Over Attributes
  //         for (let k = 0; k < tmp_old.attributes.length; k++) {
  //           // Get Attribute
  //           const attr = tmp_old.attributes[k];

  //           // Set Attribute
  //           script.setAttribute(attr.nodeName, attr.nodeValue);
  //         }

  //         // Inline Script
  //         if (tmp_old.innerHTML) {
  //           script.innerHTML = tmp_old.innerHTML;
  //         }

  //         // Replace
  //         tmp_old.parentNode.replaceChild(script, tmp_old);

  //         // Clean Arrays
  //         // old_scripts.splice(i, 1);
  //         new_scripts.splice(j, 1);

  //         // Exit Loop
  //         founded = true;
  //         break;
  //       }
  //     }

  //     if ( !founded ) {
  //       old_scripts_to_remove.push(tmp_old);
  //     }
  //   }

  //   // Remove Useless
  //   // debug('old scripts that are not in the new page', old_scripts_to_remove);
  //   for (const script of old_scripts_to_remove) {
  //     debug('remove script', script.outerHTML)
  //     // Remove
  //     script.parentNode.removeChild(script);
  //   }

  //   // Add Scripts
  //   // debug('new scripts that are not in the old page', new_scripts);
  //   for (const script of new_scripts) {
  //     debug('add script', script.outerHTML)
  //     const loc = script.parentNode.tagName;

  //     if (loc === 'HEAD') {
  //       document.head.appendChild(script);
  //     }

  //     if (loc === 'BODY') {
  //       document.body.insertBefore(script, main);
  //       // document.body.appendChild(script);
  //     }
  //   }
  // }

  // manageStyles(to) {
  //   // Your main css file, used to prepend other styles
  //   // const main = document.querySelector('#bao-style-css');
  //   const main = document.querySelector('#bao-style-first-css');

  //   const new_styles = [...to.page.querySelectorAll('style:not([data-no-reload]), link:not([data-no-reload])')];
  //   const old_styles = [...document.querySelectorAll('style:not([data-no-reload]), link:not([data-no-reload])')];
  //   const old_styles_to_remove = [];

  //   // debug('new styles', new_styles);
  //   // debug('old styles', old_styles);

  //   // Compare Styles
  //   for (let i = 0; i < old_styles.length; i++) {
  //     let founded = false;
  //     const tmp_old = old_styles[i];
  //     const tmp_old_is_link = tmp_old.tagName === 'LINK';

  //     // debug('style id:', tmp_old.id);

  //     for (let j = 0; j < new_styles.length; j++) {
  //       const tmp_new = new_styles[j];

  //       if (
  //         (tmp_old_is_link && tmp_old.id === tmp_new.id) ||
  //         tmp_old.outerHTML === tmp_new.outerHTML
  //       ) {
  //         // if (tmp_old_is_link)
  //         //   debug('-> same style');
  //         // else
  //         //   debug('-> same style', tmp_old.outerHTML);

  //         // Create Shadow Style
  //         const style = document.createElement(tmp_old.tagName);

  //         // Loop Over Attributes
  //         for (let k = 0; k < tmp_old.attributes.length; k++) {
  //           // Get Attribute
  //           const attr = tmp_old.attributes[k];

  //           // Set Attribute
  //           style.setAttribute(attr.nodeName, attr.nodeValue);
  //         }

  //         // Style Tag
  //         if (tmp_old.tagName === 'STYLE') {
  //           if (tmp_old.innerHTML) {
  //             style.innerHTML = tmp_old.innerHTML;
  //           }
  //         }

  //         // Replace
  //         tmp_old.parentNode.replaceChild(style, tmp_old);

  //         // Clean Arrays
  //         // old_styles.splice(i, 1);
  //         new_styles.splice(j, 1);

  //         // Exit Loop
  //         founded = true;
  //         break;
  //       }
  //     }

  //     if ( !founded ) {
  //       old_styles_to_remove.push(tmp_old);
  //     }
  //   }

  //   // Remove Useless
  //   // debug('old styles that are not in the new page', old_styles_to_remove);
  //   for (const style of old_styles_to_remove) {
  //     debug('remove style', style.outerHTML)
  //     // Remove
  //     style.parentNode.removeChild(style);
  //   }

  //   // Add Styles
  //   // debug('new styles that are not in the old page', new_styles);
  //   for (const style of new_styles) {
  //     debug('add style', style.outerHTML)
  //     const loc = style.parentNode.tagName;

  //     if (loc === 'HEAD') {
  //       document.head.insertBefore(style, main);
  //     }

  //     if (loc === 'BODY') {
  //       document.body.appendChild(style);
  //     }
  //   }
  // }
}
